html, body {
    margin: 0;
    height: 100% !important;
    min-height: 100% !important;
    font-size: 14px;
    background-color: #fafafa !important;
}

.logout_btn {
    cursor: pointer;
}

#root, #root-content, .wrapper {
    height: 100% !important;
    min-height: 100% !important;
}

.content-wrapper {
    min-height: 100% !important;
    margin-left: 0 !important;
}

img.imageRisque {
    max-width: 200px;
    max-height: 200px;
}

.Demo__map-maker-icon {
    color: #FD6C6C;
}

.Demo__github-icon {
    font-size: 24px;
}

.Demo__github-link {
    color: #262626;
    font-size: 20px;
}

.Demo__github-link:hover, .Demo__github-link:active {
    color: #4078c0;
    text-decoration: none;
}

.Demo__suggestion-icon {
    margin-right: 8px;
}

.Demo__search-bar-container {
    width: 90%;
    max-width: 500px;
    margin: 40px auto 0;
}

.Demo__search-input-container {
    position: relative;
}

.location-search-input, .location-search-input:focus, .location-search-input:active {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    border: honeydew;
    display: block;
    width: 100%;
    padding: 16px;
    font-size: 16px;
    border-radius: 2px;
    outline: none;
}

.autocomplete-dropdown-container {
    border-bottom: honeydew;
    border-left: honeydew;
    border-right: honeydew;
    border-top: 1px solid #e6e6e6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 2px 2px;
}

.suggestion-item {
    padding: 8px;
    text-align: left;
    background-color: #fff;
    cursor: pointer;
}

.suggestion-item--active {
    background-color: #fafafa;
}

.Demo__dropdown-footer {
    display: flex;
    justify-content: flex-end;
    padding: 4px;
}

.Demo__dropdown-footer-image {
    display: inline-block;
    width: 150px;
}

.Demo__spinner {
    color: #18bc9c;
    font-size: 30px;
}

.Demo__error-message {
    color: red;
}

.Demo__geocode-result-header {
    font-size: 20px;
    color: #222222;
}

.MuiFormControl-root {
    width: 100%;
}

.mb15 {
    margin-bottom: 15px;
}

.mb45 {
    margin-bottom: 45px;
}